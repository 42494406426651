import type { BigNumberish } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import { getToken } from "./tokens";
import { currentChainId } from "./wallet/connectors";

export const isAddress = (value: string | null | undefined) => {
  if (!value) return false;
  try {
    return ethers.utils.getAddress(value.toLowerCase());
  } catch {
    return false;
  }
};

export const getProviderOrSigner = (library: any) => {
  const provider = new ethers.providers.Web3Provider(library?.currentProvider || library?.provider);
  return provider?.getSigner();
};

export const getContract = (address: string, ABI: AbiItem | AbiItem[] | any, library: any) => {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new ethers.Contract(address, ABI, getProviderOrSigner(library));
};

export function shortenHex(hex: string, length = 4) {
  return `${hex.substring(0, length + 2)}…${hex.substring(hex.length - length)}`;
}

type EthChainIds = 1 | 3 | 4 | 5 | 42 | 11155111;

const ETHERSCAN_PREFIXES = {
  1: "",
  3: "ropsten.",
  4: "rinkeby.",
  5: "goerli.",
  42: "kovan.",
  11155111: "sepolia."
};

export const formatEtherscanLink = (
  type: "Account" | "Transaction",
  data: [EthChainIds, string]
) => {
  const [chainId, val] = data;
  const link = `https://${ETHERSCAN_PREFIXES[chainId]}etherscan.io`;
  switch (type) {
    case "Account": {
      return `${link}/address/${val}`;
    }
    case "Transaction": {
      return `${link}/tx/${val}`;
    }
  }
};

export const parseBalance = (value: BigNumberish | undefined, decimals = 18) =>
  formatUnits(value || 0, decimals).toString();

export const parsTokenBalance = (value: BigNumberish | undefined, token: string) => {
  const decimals = getToken(token)?.decimals || 18;
  return formatUnits(value || 0, decimals).toString();
};

export const fromWei = (value: BigNumberish | undefined) => parseBalance(value, 18);

export const toWei = (value: string) => Web3.utils.toWei(value);

export const prettyTx = (text: string, startLength = 6, endLength = 4) => {
  const start = text.substr(0, startLength);
  const end = text.substr(-endLength);
  return `${start}...${end}`;
};

export const formatNumberToUnit = (
  n: any = 0,
  digits = 2,
  bigDigits = 4,
  unit?: "M" | "K" | "" | "B",
  sign = ""
) => {
  if (isNaN(n) || !isFinite(n) || n === "NaN" || n === null || n === "") {
    return "-";
  }

  if (ethers.BigNumber.isBigNumber(n)) {
    n = n.toString();
  }

  n = new BigNumber(n);
  if (n.abs().isLessThan(10000)) {
    if (unit === "M") {
      return sign + "0M";
    }
    if (n.isEqualTo(0)) return sign + "0";
    return (
      sign +
      n
        .toFixed(digits)
        .toString()
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    );
  }
  let divider = 1;

  if (n.abs().gte(1000000000)) {
    divider = 1000000000;
    unit = "B";
  } else if (n.abs().gte(1000000)) {
    divider = 1000000;
    unit = "M";
  } else if (n.abs().gte(1000)) {
    divider = 1000;
    unit = "K";
  }

  const divided = n.dividedBy(divider);

  let numberString = divided.toFixed(bigDigits);
  if (bigDigits !== 0) {
    const split = numberString.split(".");
    numberString = new BigNumber(split[0]).toFormat() + "." + split[1];
  } else {
    numberString = new BigNumber(numberString).toFormat();
  }

  return sign + `${numberString}${unit}`;
};

export const isZero = (value: string | number) => new BigNumber(value).isZero();

export const sushiSwapLink = (tokens: string) =>
  `https://app.sushi.com/swap?chainId=${currentChainId}&tokens=ETH&tokens=${tokens}`;
