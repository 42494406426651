import { isMainnet } from "utils/common";

const PRODUCTION_CONFIG = {
  BNPL_FACTORY: "0x7edB0c8b428b97eA1Ca44ea9FCdA0835FBD88029",
  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  DAI: "0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa",
  WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  WETH: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  BUSD: "0x4c6E1EFC12FDfD568186b7BAEc0A43fFfb4bCcCf",
  REWARD: "0x5B9B2f05be3fEBFfEA574325D7247b1c40D822F9",
  BNPL: "0x84d821f7fbdd595c4c4a50842913e6b1e07d7a53",
  REWARDS_TOKEN_ADDRESS: "0x84d821f7fbdd595c4c4a50842913e6b1e07d7a53", // bnpl token
  STAKING_TOKEN_ADDRESS: "0x817211D408fbc19193e482f4900354e4CC894A44", //SushiSwap LP Token (SLP)
  STAKING_REWARDS_CONTRACT: "0x864B2BAC60311F15Ba539abc463FB71284dc2444" // StakingSushiSwap LP Token (SLP) Rewards proxy
};
const SEPOLIA_CONFIG = {
  BNPL: "0x9799b15B130ce7b5548A61a2606a81ea21988977",
  BNPL_FACTORY: "0x4362B75dF6E9756F9466500409C627A4F3694876",
  REWARD: "0xB4a071EC6E0BB81fbd28dE584e6BfB5511d10479",
  USDT: "0xaa8e23fb1079ea71e0a56f48a2aa51851d8433d0",
  DAI: "0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357",

  WETH: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
  USDC: "0x2f3a40a3db8a7e3d09b0adfefbce4f6f81927557",
  WBTC: "0xc04b0d3107736c32e19f1c62b2af67be61d63a05",
  BUSD: "0xb809b9b2dc5e93cb863176ea2d565425b03c0540",
  REWARDS_TOKEN_ADDRESS: "0x9799b15B130ce7b5548A61a2606a81ea21988977", // bnpl token
  STAKING_TOKEN_ADDRESS: "0xaa8e23fb1079ea71e0a56f48a2aa51851d8433d0", // USDT
  STAKING_REWARDS_CONTRACT: "0x104bef2e39612559c852beb1da2ec425357b3840" // SushiSwap LP Token (SLP) BankNodeLendingRewardsProxy
};

export default isMainnet ? PRODUCTION_CONFIG : SEPOLIA_CONFIG;
